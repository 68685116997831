<template>
  <div class="app-container">
    <ShowTitle text="個人資料" type="title" />

    <el-row>
      <el-col :span="7">
        <div class="left-menu">
          <ProfileMenu />
        </div>
      </el-col>
      <el-col :span="15">
        <el-form label-width="100px" label-position="top">
          <el-form-item label="照片">
            <el-upload
              name="fileUpload"
              class="avatar-uploader"
              :action="action"
              :show-file-list="false"
              :on-success="HandleAvatarUploadSuccess"
              :on-error="HandleAvatarUploadError"
              :data="{
                username: userInfo.username,
              }"
              :headers="{ authorization }"
            >
              <el-avatar
                fit="cover"
                size="large"
                :src="userInfo.avatar"
                icon="el-icon-user-solid"
              ></el-avatar>
            </el-upload>
            <ShowTitle
              text="你的照片會出現在你的個人資料頁面上，並與你在 Gualooa 上的其他地方一起出現。"
              type="field-sub-title"
            />
          </el-form-item>
          <el-form-item label="用户名">
            <StringLazy :string="userInfo.username" />
          </el-form-item>
          <el-form-item label="電子郵件">
            <StringLazy :string="userInfo.email" />
          </el-form-item>
          <el-form-item label="暱稱">
            <el-input
              clearable
              v-model.trim="dataObj.name"
              maxlength="50"
              show-word-limit
            />
            <ShowTitle
              text="你的暱稱將顯示在你的個人資料頁面上，作為你的署名，並與你在 Gualooa 上的其他地方一起出現。"
              type="field-sub-title"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button
            class="submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
            >保存</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";
import { userProfile, userEdit } from "@/api/user";
import { userAvatarChange } from "@/api/user";

export default {
  mixins: [mainCommon],
  data() {
    return {
      action: `${configCommon.apiHost}/api/upload/image`,
      submitLoading: false,
      dataObj: {
        name: null,
      },
    };
  },
  created() {
    this.getDataObj();
  },
  methods: {
    HandleAvatarUploadError(err, file, fileList) {
      let res = JSON.parse(err.message);
      this.pushNotification(res.message, "error");
    },
    HandleAvatarUploadSuccess(avatarRes, file) {
      if (avatarRes.code === 40000) return;
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.HandleAvatarChangeSuccess(avatarRes);
    },
    HandleAvatarChangeSuccess(avatarRes) {
      userAvatarChange({
        avatar: avatarRes.data,
      }).then((res) => {
        this.getDataObj();
        this.pushNotification(res.message);
      });
    },
    HandleSubmit() {
      this.submitLoading = true;
      userEdit({
        name: this.dataObj.name,
      })
        .then((res) => {
          this.pushNotification(res.message);
          this.getDataObj();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    getDataObj() {
      this.submitLoading = true;
      userProfile().then((res) => {
        this.dataObj = res.data;
        this.$store.state.user.userInfo = this.dataObj;
        this.submitLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.left-menu {
  top: 10px;
  position: sticky;
}
</style>